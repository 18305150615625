<!-- <app-mobile-menu class="hidden-sm hidden-lg sticky-menu"></app-mobile-menu> -->
<app-language-menu class="hidden-xs hidden-sm"></app-language-menu>
<div class="col-md-6 left-column vertical-center nopadding">
  <img class="logo vertical-center-items fade-in" src="./assets/images/logo.png" alt="logo">
  <div class="row vertical-center-items">
    <h1 class="main-title fade-in">TopTrader</h1>
    <app-separator class="red-separator fade-in"></app-separator>
    <p [innerHTML]="'home.subtitle' | translate" class="text-focus-in main-subtitle"></p>
    <button class="request-demo text-focus-in padding-button hidden-xs hidden-sm hidden-md" [routerLink]="['/about']">
      <span class="circle" aria-hidden="true">
        <span class="icon arrow"></span>
      </span>
      <span class="button-text">{{ 'home.button' | translate }}</span>
    </button>
    <button class="mobile-button text-focus-in hidden-lg" [routerLink]="['/about']">
      {{ 'home.button' | translate }}
    </button>
  </div>
</div>
<div class="right-column nopadding hidden-xs hidden-sm">
  <app-bg-animation class="fade-in"></app-bg-animation>
</div>
<app-footer></app-footer>
<!-- <app-cookies></app-cookies> -->
