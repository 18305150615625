import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {

  policies = {
    language: "",
    privacy: "https://www.iubenda.com/privacy-policy/7964238",
    cookie: "https://www.iubenda.com/privacy-policy/7964238/cookie-policy"
  }

  constructor() { }

  ngOnInit(): void {
    this.policies.language = localStorage.getItem('language')
  }

  changeLink() {
    var privacy = document.getElementById("privacy")
    privacy.setAttribute('href', this.policies.privacy)
    var cookie = document.getElementById("cookie")
    cookie.setAttribute('href', this.policies.cookie)
  }
}
