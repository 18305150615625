<!-- THE BACKOFFICE -->
<app-mobile-menu class="hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container bottom-right vertical-center">
  <div class="row">
    <div class="col-md-7 first-page-col-left">
      <h1 class="main-title text-focus-in">{{ 'backoffice.mainTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <h2 [innerHTML]="'backoffice.mainSubtitle' | translate" class="main-subtitle text-focus-in"></h2>
    </div>
    <div class="col-md-5 first-page-col-right hidden-md hidden-sm hidden-xs">
      <img class="first-page-image image-shadow slide-in-blurred-right"
        src="../../../assets/images/backoffice_home.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('multi-network', 'reason-title')"></div>
  <div class="scroll-down hidden-md hidden-lg" (click)="scrollToElement('multi-network-mobile', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- MULTI NETWORK BIG SCREENS -->
<div class="container bottom-left vertical-center hidden-sm hidden-xs" id="multi-network">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-5 slide-in-blurred-left">
      <h1 class="standard-title">{{ 'backoffice.networkTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'backoffice.networkDescription2' | translate" class="description-text"></span><br>
      <span [innerHTML]="'backoffice.networkDescription1' | translate" class="description-text"></span><br><br>
      <div class="paragraph">
        <ul>
          <li class="green-li">{{ 'backoffice.networkLi1' | translate }}</li>
          <li class="green-li">{{ 'backoffice.networkLi2' | translate }}</li>
        </ul>
      </div>
      <!-- <span [innerHTML]="'backoffice.networkDescription2' | translate" class="description-text"></span> -->
    </div>
    <div class="col-md-4 slide-in-blurred-right">
      <h1 class="standard-title">{{ 'backoffice.completeTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'backoffice.completeDescription1' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.completeDescription2' | translate"  class="description-text"></span>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('analytics', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>

<!-- MULTI NETWORK SMALL SCREEN -->
<div class="container bottom-left vertical-center fade-in hidden-md hidden-lg" id="multi-network-mobile">
  <div class="row mobile">
    <div class="col-md-10">
      <h1 class="standard-title">{{ 'backoffice.networkTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'backoffice.networkDescription2' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.networkDescription1' | translate" class="description-text"></span><br><br>
      <div class="paragraph">
        <ul>
          <li class="green-li">{{ 'backoffice.networkLi1' | translate }}</li>
          <li class="green-li">{{ 'backoffice.networkLi2' | translate }}</li>
        </ul>
      </div>
      <!-- <span [innerHTML]="'backoffice.networkDescription2' | translate" class="description-text"></span> -->
    </div>
  </div><br>
  <div class="row mobile">
    <div class="col-md-10">
      <h1 class="standard-title">{{ 'backoffice.completeTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'backoffice.completeDescription1' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.completeDescription2' | translate" class="description-text"></span>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('analytics', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- ANALYTICS-->
<div class="container vertical-center bottom-right" id="analytics">
  <div class="row hidden-md-up">
    <div class="col-md-5">
      <img class="analytics-image" src="../../../assets/images/analytics.png">
    </div>
    <div class="col-md-5">
      <h1 class="standard-title">{{ 'backoffice.analyticsTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <span class="description-text">{{ 'backoffice.analyticsDescription1' | translate }}</span><br><br>
      <div class="paragraph">
        <ul>
          <li class="red-li">{{ 'backoffice.analyticsLi1' | translate }}</li>
          <li class="red-li">{{ 'backoffice.analyticsLi2' | translate }}</li>
          <li class="red-li">{{ 'backoffice.analyticsLi3' | translate }}</li>
          <li class="red-li">{{ 'backoffice.analyticsLi4' | translate }}</li>
        </ul>
      </div>
    </div>
  </div><br><br>
  <span [innerHTML]="'backoffice.analyticsDescription2' | translate" class="description-text"></span>
  <div class="scroll-down" (click)="scrollToElement('prize-bands', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- PRIZE BANDS -->
<div class="container vertical-center bottom-left" id="prize-bands">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-8 mobile">
      <h1 class="standard-title">{{ 'backoffice.bandsTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator><br>
      <span [innerHTML]="'backoffice.bandsDescription1' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.bandsDescription2' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.bandsDescription3' | translate" class="description-text"></span><br><br>
      <span [innerHTML]="'backoffice.bandsDescription4' | translate" class="description-text"></span>
    </div>
    <div class="col-md-4 mobile hidden-xs">
      <img class="analytics-image" src="../../../assets/images/price_bands.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('commission-bands', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- COMMISSION BANDS -->
<div class="container vertical-center bottom-right" id="commission-bands">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-7 mobile">
      <h1 class="standard-title">{{ 'backoffice.commissionTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator><br>
      <span [innerHTML]="'backoffice.commissionDescription' | translate" class="description-text"></span><br><br>
      <div class="paragraph">
        <ul>
          <li class="red-li">{{ 'backoffice.commissionLi1' | translate }}</li>
          <li class="red-li">{{ 'backoffice.commissionLi2' | translate }}</li>
          <li class="red-li">{{ 'backoffice.commissionLi3' | translate }}</li>
          <li class="red-li">{{ 'backoffice.commissionLi4' | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="col-md-5 mobile hidden-xs">
      <img class="analytics-image" src="../../../assets/images/commission_bands.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('so-much-more', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- SO MUCH MORE -->
<div class="container vertical-center bottom-left" id="so-much-more">
  <h1 class="standard-title">{{ 'backoffice.moreTitle' | translate }}</h1>
  <app-separator class="green-separator"></app-separator><br>
  <div class="description">
    <span [innerHTML]="'backoffice.moreDescription1' | translate" class="description-text hidden-xs"></span><br><br>
  </div>
  <div class="description hidden-sm hidden-md hidden-lg text-center">
    <span [innerHTML]="'backoffice.moreDescription1' | translate" class="description-text"></span><br><br>
  </div>
  <span class="description-text"><span class="bold-text">{{ 'backoffice.moreDescription2' | translate }}</span></span><br><br>
  <h1 class="special-title hidden-xs" href="contact">{{ 'backoffice.moreTitle2' | translate }}</h1>
  <div class="row text-center hidden-sm hidden-md hidden-lg">
    <h1 class="special-title" href="contact">{{ 'backoffice.moreTitle2' | translate }}</h1>
  </div>
  <div class="scroll-down" (click)="scrollToElement('demo-request', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- DEMO REQUEST -->
<app-request-demo id="demo-request"></app-request-demo>
<app-footer></app-footer>