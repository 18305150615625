import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../_services/translation.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(key: any, args?: any) : any {
    let keys: string[] = key.split('.');
    var label = this.translate.data[keys[0]];
    for (var i = 1; i < keys.length; i++) {
      label = label[keys[i]];
    }
    return label || key; //this.translate.data[key] || key;
  }
}
