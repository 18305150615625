<div class="arrow arrow--top">
  <svg xmlns="http://www.w3.org/2000/svg" width="270.11" height="649.9" overflow="visible">
    <style>
      .geo-arrow {
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-miterlimit: 10
      }
    </style>
    <g class="item-to bounce-1">
      <path class="geo-arrow draw-in" d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z" />
    </g>
    <circle class="geo-arrow item-to bounce-2" cx="194.65" cy="69.54" r="7.96" />
    <circle class="geo-arrow draw-in" cx="194.65" cy="39.5" r="7.96" />
    <circle class="geo-arrow item-to bounce-3" cx="194.65" cy="9.46" r="7.96" />
    <g class="geo-arrow item-to bounce-2">
      <path class="st0 draw-in" d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552" />
    </g>
  </svg>
  <!-- <svg xmlns="http://www.w3.org/2000/svg" class="dotted-circle" width="300" height="300" overflow="visible">
    <g>
      <circle cx="150" cy="150" r="100" fill="none" stroke-miterlimit="10" stroke-dasharray="12.921,11.9271"/>
      <image x="38%" y="38%" width="70" height="70" xlink:href="./assets/images/ttg_skype.png"></image>
      <a class="demo-link" href="#demo" routerLinkActive="router-link-active" >
        <text class="text-demo" x="70%" y="50%">DEMO</text>
      </a>
      <a class="demo-link" href="#home" routerLinkActive="router-link-active" >
        <text class="text-demo" x="5%" y="50%" alignment-baseline="middle">HOME</text>
      </a>
    </g>
  </svg> -->
</div>
<div class="arrow arrow--bottom">
    <svg xmlns="http://www.w3.org/2000/svg" width="31.35" height="649.9" overflow="visible">
      <style>
        .geo-arrow {
          fill: none;
          stroke: #fff;
          stroke-width: 2;
          stroke-miterlimit: 10
        }
      </style>
      <g class="item-to bounce-1">
        <circle class="geo-arrow item-to bounce-3" cx="15.5" cy="580.36" r="7.96" />
        <circle class="geo-arrow draw-in" cx="15.5" cy="610.4" r="7.96" />
        <circle class="geo-arrow item-to bounce-2" cx="15.5" cy="640.44" r="7.96" />
        <g class="item-to bounce-2">
          <path class="geo-arrow draw-in" d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552" />
        </g>
      </g>
    </svg>
</div>