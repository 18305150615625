<div class="arrow arrow--top">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 450 450">
    <defs>
      <path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" id="textcircle">
        <animateTransform
          attributeName="transform"
          begin="0s"
          dur="30s"
          type="rotate"
          from="0 250 250" 
          to="360 250 250"
          repeatCount="indefinite"/>
      </path>
    </defs>
    <g>
      <a class="image-trigger" href="">
        <image class="image-trigger blink-1" x="40%" y="40%" width="150" height="150"
          xlink:href="./assets/images/ttg_skype.png"></image>
      </a>
      <!-- <a class="menu text-trigger" href="menu">
        <text dy="70" transform="rotate(115, 250, 250)" textLength="150">
          <textPath xlink:href="#textcircle">Demo</textPath>
        </text>
      </a> -->
      <a class="menu text-trigger" href="firm">
        <text dy="70" transform="rotate(60, 250, 250)" textLength="150">
          <textPath xlink:href="#textcircle">Firm</textPath>
        </text>
      </a>
      <a class="menu text-trigger" href="about">
        <text dy="70" transform="rotate(-3, 250, 250)" textLength="180">
          <textPath xlink:href="#textcircle">About</textPath>
        </text>
      </a>
      <a class="menu text-trigger" href="backoffice">
        <text dy="70" transform="rotate(112, 250, 250)" textLength="350">
          <textPath xlink:href="#textcircle">Backoffice</textPath>
        </text>
      </a>
      <a class="menu text-trigger" href="contact">
        <text dy="70" transform="rotate(272, 250, 250)" textLength="250">
          <textPath xlink:href="#textcircle">Contact</textPath>
        </text>
      </a>
      <a class="menu text-trigger" href="game">
        <text dy="70" transform="rotate(220, 250, 250)" textLength="150">
          <textPath xlink:href="#textcircle">Game</textPath>
        </text>
      </a>
    </g>
  </svg>
</div>
