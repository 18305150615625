  <!-- Site footer -->
  <app-cookies></app-cookies>
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h6>{{ 'footer.about' | translate }}</h6>
          <app-separator class="red-separator"></app-separator>
          <p class="text-justify">{{ 'footer.description' | translate }}</p>
        </div>

        <div class="col-xs-12 col-md-6 col-md-7">
          <h6>Menu</h6>
          <app-separator class="red-separator"></app-separator>
          <div class="link-container">
            <ul class="footer-links">
              <li><a href="">{{ 'mobile-menu.home' | translate }}</a></li>
              <li><a href="about">{{ 'mobile-menu.about' | translate }}</a></li>
              <li><a href="game">{{ 'mobile-menu.game' | translate }}</a></li>
              <li><a href="backoffice">{{ 'mobile-menu.backoffice' | translate }}</a></li>
              <li><a href="contact">{{ 'mobile-menu.contact' | translate }}</a></li>
              <li><a href="firm">{{ 'mobile-menu.firm' | translate }}</a></li>
              <li><a href="terms">{{ 'mobile-menu.terms' | translate }}</a></li>
              <li [innerHTML]="'policies.privacy' | translate"></li>
              <li [innerHTML]="'policies.cookie' | translate"></li>
            </ul>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-6 col-xs-12">
          <p [innerHTML]="'footer.copyright' | translate" class="copyright-text">Cookie</p>
        </div>
        <!-- <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="dribbble" href="#"><i class="fa fa-dribbble"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
          </ul>
        </div> -->
      </div>
    </div>
  </footer>
