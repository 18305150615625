import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/_services/translation.service';

@Component({
  selector: 'app-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss']
})
export class LanguageMenuComponent implements OnInit {

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }
  
  setLanguage(language: string) {
    this.translateService.use(language);
    // window.location.reload();
  }
}
