<!-- HOME PAGE -->
<app-mobile-menu class="hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container bottom-right vertical-center">
  <div class="row">
    <div class="hidden-lg">
      <div class="row aligner hidden-lg" style="margin: auto; width: 100vw;">
        <iframe *ngIf="videoId != ''" class="aligner-item embed-responsive-item aligner-item" frameborder="0"
          scrolling="no" [src]="videoId" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
      <div class="text-center hidden-lg" style="margin: auto; width: 100vw;">
        <h1 class="main-title text-focus-in">TopTrader</h1>
        <app-separator class="red-separator"></app-separator>
        <p [innerHTML]="'home.subtitle' | translate" class="text-focus-in main-subtitle"></p>
        <br>
      </div>
    </div>
    <div class="col-md-6 first-page-col-left">
      <div class="hidden-xs hidden-sm hidden-md">
        <h1 class="main-title text-focus-in">TopTrader</h1>
        <app-separator class="red-separator"></app-separator>
        <p [innerHTML]="'home.subtitle' | translate" class="text-focus-in main-subtitle"></p>
        <br>
      </div>
    </div>
    <div class="col-md-6 first-page-col-right hidden-xs hidden-md aligner embed-responsive embed-responsive-16by9">
      <iframe *ngIf="videoId != ''" class="embed-responsive-item aligner-item" frameborder="0" scrolling="no"
        [src]="videoId" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </div>
  <div class="scroll-down hidden-xs hidden-sm hidden-md" (click)="scroll('card-page')"></div>
  <div class="scroll-down hidden-lg" (click)="scroll('card-page-mobile')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md  parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left"></app-reactive-menu>

</div>
<!-- CARDS -->
<div class="container bottom-left vertical-center fade-in hidden-xs hidden-sm" id="card-page">
  <div class="row">
    <h1 class="card-page-title text-focus-in">{{ 'about.whatis' | translate }}</h1>
  </div>
  <div class="row hidden-md-up">
    <div class="col-md-4">
      <div class="flip-card slide-in-blurred-left text-center">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-1">
            <h3 class="card-title">{{ 'about.card1Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card1Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="flip-card text-focus-in text-center">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-2">
            <h3 class="card-title">{{ 'about.card2Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card2Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="flip-card slide-in-blurred-right text-center">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-3">
            <h3 class="card-title">{{ 'about.card3Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card3Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('target', 'reason-title')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-md"></app-bg-animation-no-circles>
</div>
<!-- CARDS MOBILE -->
<div class="container bottom-left vertical-center fade-in hidden-md hidden-lg" id="card-page-mobile">
  <div class="row mobile-row">
    <h1 class="card-page-title text-focus-in">{{ 'about.whatis' | translate }}</h1>
  </div>
  <div class="row hidden-md-up">
    <div class="col-md-4">
      <div class="flip-card slide-in-blurred-left text-center mobile-card-padding">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-1">
            <h3 class="card-title">{{ 'about.card1Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card1Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mobile-row">
    <div class="col-md-4">
      <div class="flip-card text-focus-in text-center">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-2">
            <h3 class="card-title">{{ 'about.card2Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card2Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mobile-row">
    <div class="col-md-4">
      <div class="flip-card slide-in-blurred-right text-center">
        <div class="flip-card-inner">
          <div class="flip-card-front button-shadow card-3">
            <h3 class="card-title">{{ 'about.card3Title' | translate }}</h3>
          </div>
          <div class="flip-card-back button-shadow">
            <p [innerHTML]="'about.card3Text' | translate" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-down" (click)="scroll('target')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md"></app-bg-animation-no-circles>
</div>
<!-- WHY TOPTRADER -->
<div class="container bottom-left vertical-center fade-in" id="target">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-7 description">
      <div class="card-text slide-in-blurred-left">
        <h1 class="big-title text-focus-in" id="reason-title">{{ 'about.whyTitle' | translate}}</h1>
        <app-separator class="green-separator"></app-separator>
        <div class="description">
          <h2 class="paragraph-title">{{ 'about.whySubtitle1' | translate}}</h2>
          <span [innerHTML]="'about.whyDescription1' | translate"></span><br>
          <h2 class="paragraph-title">{{ 'about.whySubtitle2' | translate}}</h2>
          <span [innerHTML]="'about.whyDescription2' | translate"></span><br>
          <h2 class="paragraph-title">{{ 'about.whySubtitle3' | translate}}</h2>
          <span [innerHTML]="'about.whyDescription3' | translate"></span><br>
        </div>
      </div>
    </div>
    <div class="col-md-4 hidden-xs hidden-sm">
      <img class="image slide-in-blurred-right" src="assets/images/marco.png">
    </div>
  </div>
  <!-- <div class="scroll-down" (click)="scrollToElement('appeal', 'appeal-title')"></div> -->
  <div class="scroll-down" (click)="scroll('appeal')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md"></app-bg-animation-no-circles>
</div>
<!-- A GREAT APPEAL -->
<div class="container bottom-right vertical-center fade-in" id="appeal">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-7 mobile">
      <h1 class="big-title text-focus-in">{{ 'about.appealTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <div class="slide-in-blurred-left text-left description">
        <span>{{ 'about.appealDescription1' | translate }}</span><br>
        <span [innerHTML]="'about.appealDescription2' | translate"></span><br><br>
        <span [innerHTML]="'about.appealDescription3' | translate"></span><br>
        <span class="bold-text">{{ 'about.appealDescription4' | translate }}</span><br><br>
        <span [innerHTML]="'about.appealDescription5' | translate"></span><br><br>
      </div>
      <div class="slide-in-blurred-left paragraph">
        <ul>
          <li class="red-li">{{ 'about.appealLi1' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi2' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi3' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi4' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi5' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi6' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi7' | translate }}</li>
          <li class="red-li">{{ 'about.appealLi8' | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="col-md-4 hidden-xs hidden-sm">
      <img class="image slide-in-blurred-right" src="../../../assets/images/iphone.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('earnings', 'appeal-title')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md"></app-bg-animation-no-circles>
</div>
<!-- EARNINGS AND RISK -->
<div class="container bottom-left vertical-center" id="earnings">
  <div class="row hidden-md-up vertical-center">
    <div class="col-md-4 slide-in-blurred-left hidden-xs hidden-sm hidden-md">
      <img class="image" src="../../../assets/images/earnings.png">
    </div>
    <div class="col-md-6 paragraph text-focus-in">
      <h1 class="big-title">{{ 'about.earningsTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <div [innerHTML]="'about.earningsDescription' | translate" class="description slide-in-blurred-right"></div>
    </div>
  </div>
  <div class="scroll-down" (click)="scroll('environment')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md"></app-bg-animation-no-circles>
</div>
<!-- ENVIRONMENT -->
<div class="container bottom-right vertical-center" id="environment">
  <div class="row">
    <div class="col-md-7 paragraph slide-in-blurred-left">
      <h1 class="big-title">{{ 'about.environmentTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <div [innerHTML]="'about.environmentDescription' | translate" class="description"></div>
      <div class="row">
        <ul>
          <li class="red-li">{{ 'about.environmentLi1' | translate }}</li>
          <li class="red-li">{{ 'about.environmentLi2' | translate }}</li>
          <li class="red-li">{{ 'about.environmentLi3' | translate }}</li>
          <li class="red-li">{{ 'about.environmentLi4' | translate }}</li>
          <li class="red-li">{{ 'about.environmentLi5' | translate }}</li>
          <li class="red-li">{{ 'about.environmentLi6' | translate }}</li>
        </ul>
      </div>
    </div>
    <div class="col-md-4 hidden-xs hidden-sm">
      <img class="image slide-in-blurred-right" src="../../../assets/images/social_full.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scroll('demo-request')"></div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left"></app-reactive-menu>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md"></app-bg-animation-no-circles>
</div>
<!-- MANAGEMENT -->
<!-- <div class="container bottom-left vertical-center" id="management">
  <div class="d-flex justify-content-around">
    <div class="col-md-6 paragraph slide-in-blurred-left">
      <h1 class="management-title">Events and Calendar</h1>
      <div class="row text-center">
        <app-separator class="green-separator"></app-separator>
      </div>
      <span>Our user-friendly and intuitive back-office allow you to monitor and to manage all the past and upcoming races. For each event you can check the number of registrants, the players who played, the operations closed and the ranking.</span><br>
      <span>You have also a calendar view, to see all the scheduled events and decide if there is anything relevant you would like to promote. Moreover, each month you have several free-roll races and guaranteed-prize events organized by the network.</span>
    </div>
    <div class="col-md-6 paragraph slide-in-blurred-right">
      <h1 class="management-title">Data and Reports</h1>
      <div class="row text-center">
        <app-separator class="green-separator"></app-separator>
      </div>
      <span>In order to have a full and complete overview of the activity we provide several reports and charts, which help to monitor and consult: all the races with the related players and trades; all the amounts played, lost and won; all the commissions and the sureties.</span><br>
      <span>Here are available also the settlements and the adjustments, all automatically calculated. This is applicable in case of sub-partners as well, easing all the management of the structure.</span>
    </div>
  </div>
  <div class="row text-focus-in">
    <div class="col-md-3"></div>
    <div class="col-md-6 paragraph">
      <div class="row">
        <h1 class="management-title">Users and Roles</h1>
        <div class="row text-center">
          <app-separator class="green-separator"></app-separator>
        </div>
        <span>To provide a full management of each aspect of the product we give the possibility to create more users and give them different roles. The roles are useful to give specific privileges or to hide information.</span><br><br>
      </div>
      <div class="row text-center">
        <span>By default, the following roles are available:</span><br><br>
        <ul class="list-center">
          <li class="green-li">Administrator</li>
          <li class="green-li">Accountant</li>
          <li class="green-li">Operator</li>
        </ul>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('demo-request', 'appeal-title')"></div>
  <app-bg-animation-no-circles></app-bg-animation-no-circles>
  <app-reactive-menu class="reactive-menu-right"></app-reactive-menu>
</div> -->
<!-- DEMO REQUEST -->
<app-request-demo id="demo-request"></app-request-demo>
<app-footer></app-footer>
