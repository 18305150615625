import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent {

  constructor(@Inject(DOCUMENT) private document: Document, private titleService: Title) {
    this.titleService.setTitle('TopTrader | Game')
  }

  scrollToElement(el, title) {
    var element = document.getElementById(el)
    element.scrollIntoView({ behavior: 'smooth',  block: "start"});
  }

  @HostListener('window:scroll', [])
  animateOnScroll(element) {
    if (this.document.body.scrollTop > 2000)
      this.document.getElementById('element').classList.add('text-focus-in')
  }
}
