import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reactive-menu',
  templateUrl: './reactive-menu.component.html',
  styleUrls: ['./reactive-menu.component.scss']
})
export class ReactiveMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
