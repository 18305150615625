import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ButtonComponent } from './_helpers/button/button.component';
import { SeparatorComponent } from './_helpers/separator/separator.component';
import { BgAnimationComponent } from './_helpers/bg-animation/bg-animation.component';
import { BgAnimationNoCirclesComponent } from './_helpers/bg-animation-no-circles/bg-animation-no-circles.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ReactiveMenuComponent } from './_helpers/reactive-menu/reactive-menu.component';
import { GameComponent } from './pages/game/game.component';
import { RequestDemoComponent } from './_helpers/request-demo/request-demo.component';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { MobileMenuComponent } from './_helpers/mobile-menu/mobile-menu.component';
import { TranslateService } from './_services/translation.service';
import { TranslatePipe } from './_helpers/translate.pipe';
import { HttpClientModule } from '@angular/common/http';
import { LanguageMenuComponent } from './_helpers/language-menu/language-menu.component';
import { FirmComponent } from './pages/firm/firm.component';
import { FooterComponent } from './_helpers/footer/footer.component';
import { TermsComponent } from './pages/terms/terms.component';
import { CookiesComponent } from './_helpers/cookies/cookies.component';

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(localStorage.getItem('language') || 'en');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ButtonComponent,
    SeparatorComponent,
    BgAnimationComponent,
    BgAnimationNoCirclesComponent,
    ContactComponent,
    ReactiveMenuComponent,
    GameComponent,
    RequestDemoComponent,
    BackofficeComponent,
    MobileMenuComponent,
    TranslatePipe,
    LanguageMenuComponent,
    FirmComponent,
    FooterComponent,
    TermsComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    },
    // NgcCookieConsentService,
    // WindowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
