import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bg-animation',
  templateUrl: './bg-animation.component.html',
  styleUrls: ['./bg-animation.component.scss']
})
export class BgAnimationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
