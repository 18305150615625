<div class="row text-center language-menu-mobile hidden-md hidden-lg">
  <div (click)="setLanguage('en')" class="col-xs-4">EN</div>
  <div (click)="setLanguage('it')" class="col-xs-4">IT</div>
  <div (click)="setLanguage('es')" class="col-xs-4">ES</div>
</div>
<div class="dropdown hidden-xs hidden-sm fade-in">
  <div (click)="setLanguage('en')" class="col-xs-4 dropdown-content">EN</div>
  <div (click)="setLanguage('it')" class="col-xs-4 dropdown-content">IT</div>
  <div (click)="setLanguage('es')" class="col-xs-4 dropdown-content">ES</div>
</div>
