import { ElementRef } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  @ViewChild('target') private myScrollContainer: ElementRef;

  videoId: SafeResourceUrl = '';
  vimeo: string = 'https://player.vimeo.com/video/601665558';
  
  constructor(
    private titleService: Title,
    public sanitizer: DomSanitizer) {
      this.titleService.setTitle('TopTrader | About')
      this.videoId = this.sanitizer.bypassSecurityTrustResourceUrl(this.vimeo)
  }

  scroll(id: string) {
    const el = document.getElementById(id);
    this.scrollCustomImplementation(el);
  }

  getSecureVideo() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.vimeo);
  }

  scrollToElement(el, title) {
    var element = document.getElementById(el)
    element.scrollIntoView({ behavior: 'smooth',  block: "start"});
  }

  scrollCustomImplementation(element: HTMLElement) {
    let start = null;
    let target = element && element ? element.getBoundingClientRect().top : 0;
    let firstPos = window.pageYOffset || document.documentElement.scrollTop;
    let pos = 0;

    (function () {
      var browser = ['ms', 'moz', 'webkit', 'o'];

      for (var x = 0, length = browser.length; x < length && !window.requestAnimationFrame; x++) {
        window.requestAnimationFrame = window[browser[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[browser[x] + 'CancelAnimationFrame'] || window[browser[x] + 'CancelRequestAnimationFrame'];
      }
    })();

    function showAnimation(timestamp) {
      if (!start) {
        start = timestamp || new Date().getTime();
      } //get id of animation


      var elapsed = timestamp - start;
      var progress = elapsed / 300; // animation duration 600ms
      //ease in function from https://github.com/component/ease/blob/master/index.js

      var outQuad = function outQuad(n) {
        return n * (2 - n);
      };

      var inQuad = function(n){
        return n * n;
      };

      var inOutQuad = function(n){
        n *= 2;
        if (n < 1) return 0.5 * n * n;
        return - 0.5 * (--n * (n - 2) - 1);
      };

      var easeInPercentage = +inOutQuad(progress).toFixed(2); // if target is 0 (back to top), the position is: current pos + (current pos * percentage of duration)
      // if target > 0 (not back to top), the positon is current pos + (target pos * percentage of duration)

      pos = target === 0 ? firstPos - firstPos * easeInPercentage : firstPos + target * easeInPercentage;
      window.scrollTo(0, pos);
      // console.log(pos, target, firstPos, progress);

      if (target !== 0 && pos >= firstPos + target || target === 0 && pos <= 0) {
        cancelAnimationFrame(start);

        if (element) {
          element.setAttribute("tabindex", '-1');
          element.focus();
        }

        pos = 0;
      } else {
        window.requestAnimationFrame(showAnimation);
      }
    }

    window.requestAnimationFrame(showAnimation);
  }
}
