<!-- THE GAME -->
<app-mobile-menu class="hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container bottom-right vertical-center fade-in">
  <div class="row">
    <div class="col-md-7 first-page-col-left">
      <h1 class="main-title text-focus-in">{{ 'game.mainTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <h2 class="main-subtitle text-focus-in">{{ 'game.mainSubtitle' | translate }}</h2>
    </div>
    <div class="col-md-5 hidden-md hidden-sm hidden-xs first-page-col-right">
      <img class="first-page-image image-shadow slide-in-blurred-right" src="../../../assets/images/game_screen.png">
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('platform-page', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<!-- THE PLATFORM -->
<div class="container bottom-left vertical-center fade-in" id="platform-page">
  <div class="row">
    <div class="col-md-10">
      <div class="slide-in-blurred-left">
        <h1 class="big-title text-focus-in">{{ 'game.platformTitle' | translate }}</h1>
        <app-separator class="green-separator"></app-separator>
        <span [innerHTML]="'game.platformDescription1' | translate" class="description-text"></span><br>
        <span [innerHTML]="'game.platformDescription2' | translate" class="description-text"></span><br><br><br>
        <div class="text-focus-in description-text">
          <ul>
            <li class="green-li">{{ 'game.platformLi1' | translate }}</li><br>
            <li class="green-li">{{ 'game.platformLi2' | translate }}</li><br>
            <li class="green-li">{{ 'game.platformLi3' | translate }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('rich-entertainment', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right slide-in-blurred-right"></app-reactive-menu>
</div>
<!-- RICH ENTERTAINMENT -->
<div class="container vertical-center bottom-right fade-in" id="rich-entertainment">
  <div class="rich-ent fade-in">
    <div class="custom-card slide-in-blurred-left button-shadow parallax">
      <h1 class="standard-title text-focus-in">{{ 'game.richEntTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'game.richEntDescription1' | translate" class="description-text-white"></span><br>
      <span [innerHTML]="'game.richEntDescription2' | translate" class="description-text-white"></span><br>
      <span [innerHTML]="'game.richEntDescription3' | translate" class="description-text-white"></span>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('faithful-replication', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right slide-in-blurred-right"></app-reactive-menu>
</div>
<!-- FAITHFUL REPLICATION -->
<div class="container bottom-left vertical-center fade-in" id="faithful-replication">
  <div class="faith-rep text-focus-in">
    <div class="custom-card slide-in-blurred-left button-shadow parallax">
      <h1 class="standard-title text-focus-in">{{ 'game.faithTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'game.faithDescription1' | translate" class="description-text-white"></span><br>
      <span [innerHTML]="'game.faithDescription2' | translate" class="description-text-white"></span>
    </div>
  </div>
  <div class="scroll-down hidden-xs" (click)="scrollToElement('smart-panel', 'reason-title')"></div>
  <div class="scroll-down hidden-sm hidden-md hidden-lg" (click)="scrollToElement('demo-request', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right slide-in-blurred-right"></app-reactive-menu>
</div>
<!-- SMART PANEL -->
<div class="container hidden-xs bottom-right vertical-center" id="smart-panel">
  <div class="smart-panel text-focus-in">
    <div class="custom-card slide-in-blurred-left button-shadow parallax">
      <h1 class="standard-title text-focus-in">{{ 'game.smartTitle' | translate }}</h1>
      <app-separator class="red-separator"></app-separator>
      <span [innerHTML]="'game.smartDescription' | translate" class="description-text-white"></span><br>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('pro-panel', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right slide-in-blurred-right"></app-reactive-menu>
</div>
<!-- PRO PANEL -->
<div class="container hidden-xs bottom-left vertical-center" id="pro-panel">
  <div class="pro-panel text-focus-in">
    <div class="custom-card slide-in-blurred-left button-shadow parallax">
      <h1 class="standard-title text-focus-in">{{ 'game.proTitle' | translate }}</h1>
      <app-separator class="green-separator"></app-separator>
      <span [innerHTML]="'game.proDescription' | translate" class="description-text-white"></span><br>
    </div>
    <div class="top-right-flyout text-focus-in">
      <span class="description-text-white">
        <ul>
          <li class="white-li">{{ 'game.proLi1' | translate }}</li>
          <li class="white-li">{{ 'game.proLi2' | translate }}</li>
          <li class="white-li">{{ 'game.proLi3' | translate }}</li>
          <li class="white-li">{{ 'game.proLi4' | translate }}</li>
        </ul>
      </span>
    </div>
  </div>
  <div class="scroll-down" (click)="scrollToElement('demo-request', 'reason-title')"></div>
  <app-bg-animation-no-circles class="hidden-xs hidden-sm hidden-md parallax"></app-bg-animation-no-circles>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-right slide-in-blurred-right"></app-reactive-menu>
</div>
<!-- DEMO REQUEST -->
<app-request-demo id="demo-request"></app-request-demo>
<app-footer></app-footer>
