import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss']
})
export class FirmComponent {

  constructor(private titleService: Title) {
    this.titleService.setTitle('TopTrader | Firm')
  }
}
