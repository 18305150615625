<app-mobile-menu class="hidden-md hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container-fluid">
  <div class="row terms-and-conditions-section">
    <div class="col-xs-12 text-center">
      <h1 class="title">{{ 'terms.title' | translate }}</h1>
      <h3 class="subtitle">{{ 'terms.subtitle' | translate }}</h3>
    </div>

    <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
      <div class="content">
        <p>{{ 'terms.intro1' | translate }}</p>
        <p>{{ 'terms.intro2' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section1title' | translate }}</h2>
        <p>{{ 'terms.section1p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section2title' | translate }}</h2>
        <p>{{ 'terms.section2p1' | translate }}</p>
        <p>{{ 'terms.section2p2' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section3title' | translate }}</h2>
        <p>{{ 'terms.section3p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section4title' | translate }}</h2>
        <p>{{ 'terms.section4p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section5title' | translate }}</h2>
        <p>{{ 'terms.section5p1' | translate }}</p>
        <p>{{ 'terms.section5p2' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section6title' | translate }}</h2>
        <p>{{ 'terms.section6p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section7title' | translate }}</h2>
        <p>{{ 'terms.section7p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section8title' | translate }}</h2>
        <p>{{ 'terms.section8p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section9title' | translate }}</h2>
        <p>{{ 'terms.section9p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section10title' | translate }}</h2>
        <p>{{ 'terms.section10p1' | translate }}</p>

        <h2 class="section-title">{{ 'terms.section11title' | translate }}</h2>
        <ul>
          <li>{{ 'terms.section11p1' | translate }}</li>
          <li>{{ 'terms.section11p2' | translate }}</li>
        </ul>

        <h2 class="section-title">{{ 'terms.section12title' | translate }}</h2>
        <p>{{ 'terms.section12p1' | translate }}</p>
        <p>{{ 'terms.section12p2' | translate }}</p>
        <p>{{ 'terms.section12p3' | translate }}</p>
        <p>{{ 'terms.section12p4' | translate }}</p>
        <p>{{ 'terms.section12p5' | translate }}</p>
        <h2 class="section-title">{{ 'terms.section13title' | translate }}</h2>
        <p>{{ 'terms.section13p1' | translate }}</p>
        <p>{{ 'terms.section13p2' | translate }}</p>
      </div>
    </div>
  </div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left" style="margin-left: -5%;"></app-reactive-menu>
</div>
<app-footer></app-footer>