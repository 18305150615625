import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  hidden: boolean;
  constructor() {
    if (localStorage.getItem('cookieSeen') == 'shown')
      this.hidden = true;
  }

  close() {
    this.hidden = true;
    localStorage.setItem('cookieSeen', 'shown')
  }
}
