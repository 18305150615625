import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bg-animation-no-circles',
  templateUrl: './bg-animation-no-circles.component.html',
  styleUrls: ['./bg-animation-no-circles.component.scss']
})
export class BgAnimationNoCirclesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
