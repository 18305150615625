<app-mobile-menu class="hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container bottom-right">
  <form class="form fade-in">
    <h2>{{ 'contact.mainTitle' | translate }}</h2><br>
    <p type="Name:"><input type="text" placeholder="{{ 'contact.placeholderName' | translate }}"></p>
    <p type="Email:"><input type="text" placeholder="{{ 'contact.placeholderMail' | translate }}"></p>
    <p type="Message:"><input type="text" placeholder="{{ 'contact.placeholderMessage' | translate }}"></p>
    <button href="mailto:info@ttgames.it?subject=Website%20Contact">{{ 'contact.button' | translate}}</button>
    <div class="flyout slide-in-blurred-left">
      <span class="fa fa-phone">{{ 'contact.bottomLeftFlyout' | translate}}</span>
    </div>
    <div class="flyout-info slide-in-blurred-right hidden-xs">
        <h3>INFO</h3>
        <ul class="mail">
            <li class="white-li">info@ttgames.it</li>
            <li class="white-li">+39 345 5831585</li>
        </ul>
    </div>
  </form>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
  <app-bg-animation-no-circles class="contact-animation"></app-bg-animation-no-circles>
</div>
<app-footer></app-footer>