import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { ContactComponent } from './pages/contact/contact.component';
import { GameComponent } from './pages/game/game.component';
import { FirmComponent } from './pages/firm/firm.component';
import { TermsComponent } from './pages/terms/terms.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "game", component: GameComponent },
  { path: "backoffice", component: BackofficeComponent },
  { path: "firm", component: FirmComponent },
  { path: "terms", component: TermsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
