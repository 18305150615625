import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};
  public currentLng: string = localStorage.getItem(('language') || 'en');

  constructor(private http: HttpClient) { }

  use(lang: string): Promise<{}> {
    if (this.currentLng == null || this.currentLng == "null") this.currentLng = 'en'
    return new Promise<{}>((resolve, reject) => {
      if (lang == "null" || lang == null || lang == undefined)
        lang = 'en'
      const langPath = `./assets/i18n/${lang || 'en'}.json`; //`${Constants.storageUrl}${lang || 'en'}.json`;
      localStorage.setItem('language', lang)
      this.http
        .get<{}>(langPath)
        .subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }

  public t(key: any) : any {
    let keys: string[] = key.split('.');
    var label = this.data[keys[0]];
    for (var i = 1; i < keys.length; i++) {
      label = label[keys[i]];
    }
    return label || key;
  }

  public translateCombo(text: string) : string {
    let isI18N: boolean = false, isDate: boolean = false;
    let toTranslate: string = '', result: string = '';

    var gmtValue: number = (new Date()).getTimezoneOffset() * -60;

    for (var i = 0; i < text.length; i++) {
      if (text[i] == ']') {
        isI18N = false;
        if (toTranslate != '') {
          result += this.t(toTranslate);
          toTranslate = '';
        }
      }
      if (text[i] == ' ' && isDate) {
        isDate = false;
        if (toTranslate != '') {
          result += new Date(parseInt(toTranslate) * 1000).toLocaleDateString();
          result += ' ' + new Date(parseInt(toTranslate) * 1000).toLocaleTimeString()
          toTranslate = '';
        }
      }
      if (isI18N) {
        toTranslate += text[i];
      }
      if (text[i] == '[') {
        isI18N = true;
      }
      if (isDate) {
        toTranslate += text[i];
      }
      if (text[i] == '|') {
        isDate = true;
      }
      if (!isI18N && !isDate && text[i] != '[' && text[i] != ']') {
        result += text[i];
      }
    }

    return result;
  }
}
