<app-mobile-menu class="hidden-lg sticky-menu"></app-mobile-menu>
<app-language-menu></app-language-menu>
<div class="container bottom-right vertical-center">
  <div class="row hidden-xs hidden-sm hidden-md">
    <h1 class="main-title text-focus-in">History</h1>
    <app-separator class="red-separator hidden-xs text-focus-in"></app-separator>
  </div>
  <div class="row mobile-spacer hidden-sm hidden-md hidden-lg"></div>
  <div class="timeline-block timeline-block-right slide-in-blurred-left">
    <div class="marker"></div>
    <div class="timeline-content">
      <img class="image" src="../../../assets/images/TTA_V2.png">
      <app-separator class="red-separator hidden-xs text-focus-in"></app-separator>
      <h3>2018</h3>
      <span>TopTrading Academy</span>
      <p>{{ 'firm.tta' | translate }}</p>
    </div>
  </div>

  <div class="timeline-block timeline-block-left slide-in-blurred-right">
    <div class="marker"></div>
    <div class="timeline-content">
      <img class="image" src="../../../assets/images/TTG_V2.png">
      <app-separator class="red-separator hidden-xs text-focus-in"></app-separator>
      <h3>2019</h3>
      <span>TopTrader Game</span>
      <p>{{ 'firm.ttg' | translate }}</p>
    </div>
  </div>

  <div class="timeline-block timeline-block-right slide-in-blurred-left">
    <div class="marker"></div>
    <div class="timeline-content">
      <img class="image" src="../../../assets/images/frequency.png">
      <app-separator class="red-separator hidden-xs text-focus-in"></app-separator>
      <h3>2020</h3>
      <span>TTFrequency</span>
      <p>{{ 'firm.ttf' | translate }}</p>
    </div>
  </div>

  <div class="timeline-block timeline-block-left slide-in-blurred-right">
    <div class="marker"></div>
    <div class="timeline-content">
      <img class="image" src="../../../assets/images/b2b.png">
      <app-separator class="red-separator hidden-xs text-focus-in"></app-separator>
      <h3>2021</h3>
      <span>{{ 'firm.b2bTitle' | translate }}</span>
      <p>{{ 'firm.b2b' | translate }}</p>
    </div>
  </div>
  <app-reactive-menu class="hidden-xs hidden-sm hidden-md reactive-menu-left slide-in-blurred-left"></app-reactive-menu>
</div>
<app-footer></app-footer>